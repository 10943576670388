import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';  // Import useLocation
import './NavBar.css';
import logo from './images/logo_wth_text.svg';

function Navbar({ isLoggedIn, onSignOut, pageTitle }) {
    const navigate = useNavigate();
    const location = useLocation();  // Get current location

    // Check if the current path is '/signin'
    const isSignInPage = location.pathname === '/signin';

    return (
        <nav className="navbar">
            <div className="logo" onClick={() => navigate('/control-panel')}>
                <img src={logo} alt="Hydro-Defence Logo" className="logo-image" />
            </div>
            {isLoggedIn && <div className="page-title">{pageTitle}</div>}  {/* Display Page Title when logged in */}

            {/* Hide nav-links if on the sign-in page */}
            {!isSignInPage && (
                <div className="nav-links">
                    {!isLoggedIn}
                    <button
                        onClick={() => isLoggedIn ? onSignOut() : navigate('/signin')}
                        className="sign-in-btn"
                    >
                        {isLoggedIn ? 'Sign Out' : 'Sign In'}
                    </button>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
