import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, CardContent, Typography, Grid, IconButton,
    Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { PuffLoader } from 'react-spinners';  // Import BounceLoader
import DownIcon from './images/down.svg';
import UpIcon from './images/up.svg';
import DownloadIcon from './images/download.svg';
import './HydrantsDashboard.css';

const GOOGLE_API_KEY = 'AIzaSyCFpNNh-VbkBZtcmHTORaYoYjVCa-Q2P3A';

// Utility Functions (No changes)
const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

const separateDateFromHours = (timestamp) => {
    const [date, time] = timestamp.split('T');
    return `${date.split('-').reverse().join('/')} ${time}`;
};

const fetchFreguesia = async (latitude, longitude) => {
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
        );
        const data = await response.json();

        if (data.results.length > 0) {
            const addressComponents = data.results[0].address_components;

            let freguesiaComponent = addressComponents.find(component =>
                component.types.includes('administrative_area_level_3')
            );

            if (!freguesiaComponent) {
                freguesiaComponent = addressComponents.find(component =>
                    component.types.includes('locality')
                );
            }

            return freguesiaComponent ? freguesiaComponent.long_name : 'Unknown Freguesia';
        }

    } catch (error) {
        console.error('Error fetching freguesia:', error);
    }
    return 'Unknown Freguesia';
};

// API Response Transformation (No changes)
const transformApiResponse = async (apiResponse, userToken) => {
    const transformed = await Promise.all(apiResponse.map(async (item) => {
        const latitude = getLatitudeFromLocation(item.location);
        const longitude = getLongitudeFromLocation(item.location);
        const freguesia = await fetchFreguesia(latitude, longitude);

        const historyResponse = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrantSpecs?hydrant_id=${item.hydrant_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        });

        let totalEstimation = 0;
        let openings = 0;

        if (historyResponse.ok) {
            const historyData = await historyResponse.json();
            totalEstimation = historyData.historical_data.reduce((acc, record) => acc + record.estimation, 0);
            openings = historyData.historical_data.length;
        } else {
            console.error(`Error fetching history for hydrant ${item.hydrant_id}`);
        }

        return {
            id: parseInt(item.hydrant_id),
            name: `Hydrant ${item.hydrant_id}`,
            freguesia,
            latitude,
            longitude,
            totalEstimation: parseFloat(totalEstimation.toFixed(2)),
            lastUsed: separateDateFromHours(item.date),
            openings
        };
    }));
    return transformed;
};

function HydrantsDashboard({ userEmail, userToken }) {
    const [hydrants, setHydrants] = useState([]);
    const [sortBy, setSortBy] = useState('totalEstimation');
    const [sortOrder, setSortOrder] = useState('desc');
    const [isLoading, setIsLoading] = useState(true);  // Added loading state

    useEffect(() => {
        const fetchHydrants = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrants?client_email=${userEmail}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userToken}` }
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const data = await response.json();
                const transformedData = await transformApiResponse(data, userToken);
                setHydrants(transformedData);
            } catch (error) {
                console.error('Error fetching hydrants:', error);
            } finally {
                setIsLoading(false);  // Stop loading spinner after fetch
            }
        };

        fetchHydrants();
    }, [userToken, userEmail]);

    const sortedHydrants = [...hydrants].sort((a, b) => {
        return sortOrder === 'asc' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
    });

    const hydrantsByFreguesia = hydrants.reduce((acc, hydrant) => {
        acc[hydrant.freguesia] = acc[hydrant.freguesia] || [];
        acc[hydrant.freguesia].push(hydrant);
        return acc;
    }, {});

    const downloadCSV = () => {
        const csvRows = [
            ['Hydrant', 'Total Estimation (m³)', 'Number of Openings'],
            ...sortedHydrants.map(h => [h.name, h.totalEstimation, h.openings])
        ];
        const csvContent = csvRows.map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "hydrants_data.csv";
        link.click();
    };

    const totalWaterConsumption = hydrants.reduce((sum, h) => sum + h.totalEstimation, 0);
    const topFreguesias = Object.entries(hydrantsByFreguesia)
        .map(([freguesia, hydrants]) => ({
            freguesia,
            totalConsumption: hydrants.reduce((sum, h) => sum + h.totalEstimation, 0)
        }))
        .sort((a, b) => b.totalConsumption - a.totalConsumption)
        .slice(0, 5);

    return (
        <div className="hydrants-dashboard-container">
            {isLoading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                    <PuffLoader color="#21A0A4" size={80} />
                </Grid>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Card className="freguesia-card" elevation={3} style={{ height: '97%', maxHeight: '600px', overflowY: 'auto', fontFamily: 'League Spartan' }}>
                            <CardContent>
                                <Typography variant="h6" className="card-title">Hydrants List</Typography>
                                <div className="freguesia-list">
                                    {Object.keys(hydrantsByFreguesia).map(freguesia => (
                                        <div key={freguesia}>
                                            <Typography variant="subtitle1" className="card-subtitle"><strong>{freguesia}</strong></Typography>
                                            {hydrantsByFreguesia[freguesia].map(hydrant => (
                                                <Typography key={hydrant.id} variant="body2">
                                                    <Link to={`/hydrant/${hydrant.id}`} className="hydrant-link">{hydrant.name}</Link>
                                                </Typography>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Card className="overview-card" elevation={3}>
                            <CardContent>
                                <Typography variant="h6" className="card-title">Overview</Typography>
                                <Typography className="card-subtitle">Total Water Consumption: </Typography>
                                <Typography>{totalWaterConsumption.toFixed(2)} m³</Typography>
                                <Typography variant="subtitle1" className="card-subtitle">Top 5 Parish by Water Consumption:</Typography>
                                <ul>
                                    {topFreguesias.map(freg => (
                                        <li key={freg.freguesia}>
                                            <strong>{freg.freguesia}:</strong> {freg.totalConsumption.toFixed(2)} m³
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>

                        <Card className="hydrant-table-card" elevation={3} style={{ height: '49.5%', marginTop: '20px',overflowY: 'auto',fontFamily: 'League Spartan' }}>
                            <CardContent>
                                <div className="table-header">
                                    <Typography variant="h6" className="card-title">Usages</Typography>
                                    <IconButton onClick={downloadCSV}>
                                        <img src={DownloadIcon} alt="Download CSV" />
                                    </IconButton>
                                </div>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Hydrant Name</TableCell>
                                            <TableCell>
                                                Total Consumption Estimation (m³)
                                                <IconButton onClick={() => {
                                                    setSortBy('totalEstimation');
                                                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                                                }}>
                                                    <img src={sortOrder === 'asc' ? DownIcon : UpIcon} alt="Sort" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                Number of Usages
                                                <IconButton onClick={() => {
                                                    setSortBy('openings');
                                                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                                                }}>
                                                    <img src={sortOrder === 'asc' ? DownIcon : UpIcon} alt="Sort" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedHydrants.map(hydrant => (
                                            <TableRow key={hydrant.id}>
                                                <TableCell>
                                                    <Typography key={hydrant.id} variant="body2">
                                                        <Link to={`/hydrant/${hydrant.id}`} className="hydrant-link">{hydrant.name}</Link>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{hydrant.totalEstimation.toFixed(2)} m³</TableCell>
                                                <TableCell>{hydrant.openings}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default HydrantsDashboard;
