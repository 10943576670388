import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './CustomSignIn.css';
import lettersLogo from '../src/images/letters_green.svg';

function CustomSignIn({ setUser }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

    const handleSignIn = async () => {
        try {
            const user = await Auth.signIn(username, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setIsChangePassword(true);
                setCurrentUser(user);
            } else {
                setUser(user);
                navigate('/control-panel');
            }
        } catch (error) {
            console.error('Error signing in', error);
            setModalMessage('Error signing in: ' + (error.message || 'An error occurred while signing in.'));
            setOpen(true);
        }
    };

    const handleChangePassword = async () => {
        try {
            await Auth.completeNewPassword(currentUser, newPassword);
            navigate('/control-panel');
        } catch (error) {
            console.error('Error changing password:', error);
            setModalMessage('Error changing password: ' + (error.message || 'An error occurred while changing the password.'));
            setOpen(true);
        }
    };

    const handleClose = () => setOpen(false);

    return (
        <div className="login-container">
            <div className="login-box">
                <img src={lettersLogo} alt="Hydro-Defence" className="logo-text" />

                {isChangePassword ? (
                    <>
                        <TextField
                            fullWidth
                            type="password"
                            label="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Button variant="contained" color="primary" onClick={handleChangePassword} fullWidth>
                            Change Password
                        </Button>
                    </>
                ) : (
                    <>
                        <TextField
                            fullWidth
                            label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            type="password"
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            margin="normal"
                        />
                        <button
                            onClick={handleSignIn}
                            className="sign-in-btn"
                        ><b>Sign In</b>
                        </button>
                    </>
                    )}
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle className="dialog-title">ALERT</DialogTitle>
                <DialogContent>
                    <DialogContentText>{modalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: '#b71c1c' }}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CustomSignIn;
