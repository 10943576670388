import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Navbar from './NavBar'; // Ensure Navbar is correctly imported
import './LandingPage.css';
import letters from '../src/images/letters.svg';
import heroVideo from '../src/images/Hydro-Defence.mp4';
import playIcon from '../src/images/play.svg';
import pauseIcon from '../src/images/pause.svg';
import playBarIcon from '../src/images/play_white.svg';
import pauseBarIcon from '../src/images/pause_white.svg';
import muteIcon from '../src/images/mute_white.svg';
import unmuteIcon from '../src/images/sound_white.svg';
import controlPanelImage from '../src/images/control-panel-image.png';
import mapImage from '../src/images/map-image.png';
import hydrantsDashboardImage from '../src/images/hydrants-dashboard-image.png';
import hydrantDashboardImage from '../src/images/hydrant-dashboard-image.png';
import emailNotification from '../src/images/email_notification.svg'; // Assuming these icons exist
import notificationImage from '../src/images/notifications.png';

function LandingPage() {
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const [showControls, setShowControls] = useState(false);
    const [showCenterIcon, setShowCenterIcon] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const videoRef = useRef(null);

    const [hydrants, setHydrants] = useState(0);
    const [clusters, setClusters] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);

    const calculatePrice = () => {
        let pricePerHydrant = 100;
        if (hydrants > 10 && hydrants <= 50) pricePerHydrant = 95;
        else if (hydrants > 50 && hydrants <= 100) pricePerHydrant = 80;
        else if (hydrants > 100) pricePerHydrant = 70;

        const calculatedPrice = (hydrants * pricePerHydrant) + (clusters * 500/3);
        setFinalPrice(calculatedPrice.toFixed(2));
    };

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
                setShowCenterIcon(pauseIcon);
            } else {
                videoRef.current.play();
                setShowCenterIcon(playIcon);
            }
            setIsPlaying(!isPlaying);

            setTimeout(() => {
                setShowCenterIcon(null);
            }, 1000);
        }
    };

    const handleMuteUnmute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    const handleShowImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setModalShow(true);
    };

    return (
        <div className="landing-page">
            <Navbar isLoggedIn={false} />

            <section className="hero">
                <img src={letters} alt="Hero Logo" className="hero-logo"/>
                <div className="hero-content">
                    <div className="text-container">
                        <p className="hero-intro">
                            Reduce costs and protect resources with cutting-edge water conservation technology. Minimize losses and maximize efficiency. </p>
                    </div>
                    <div className="video-container" onMouseEnter={() => setShowControls(true)}
                         onMouseLeave={() => setShowControls(false)}>
                        <video ref={videoRef} className="hero-video" autoPlay muted={isMuted}
                               onClick={handleVideoClick}>
                            <source src={heroVideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        {showCenterIcon && <img src={showCenterIcon} alt="Center Icon" className="center-icon"/>}
                        {showControls && (
                            <div className="video-controls">
                                <div className="control-buttons">
                                    <button onClick={handleVideoClick} className="control-btn">
                                        <img src={isPlaying ? pauseBarIcon : playBarIcon} alt="Play/Pause"/>
                                    </button>
                                    <button onClick={handleMuteUnmute} className="control-btn">
                                        <img src={isMuted ? unmuteIcon : muteIcon} alt="Mute/Unmute"/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="about">
                <h2>OUR SOLUTION</h2>
                <div className="features">
                    {[
                        {
                            img: controlPanelImage,
                            title: "Control Panel",
                            desc: "Manage and monitor all hydrants from one centralized dashboard."
                        },
                        {
                            img: mapImage,
                            title: "Map Overview",
                            desc: "Visualize all hydrant locations with real-time status updates."
                        },
                        {
                            img: hydrantsDashboardImage,
                            title: "Hydrants Dashboard",
                            desc: "Detailed insights on hydrant usage and performance metrics."
                        },
                        {
                            img: hydrantDashboardImage,
                            title: "Hydrant Detail Dashboard",
                            desc: "Comprehensive data for individual hydrant analysis and management."
                        },
                        {
                            img: notificationImage,
                            title: "Real-Time Notifications",
                            desc: "Stay informed with instant alerts on hydrant activity, ensuring timely responses to critical situations."
                        }
                    ].map((feature, index) => (
                        <div key={index} className="feature" onClick={() => handleShowImage(feature.img)}>
                            <img src={feature.img} alt={feature.title}/>
                            <h3>{feature.title}</h3>
                            <p>{feature.desc}</p>
                        </div>
                    ))}
                </div>
            </section>

            <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Expanded View</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={selectedImage} alt="Expanded Feature" style={{ width: '100%' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Hydro-Defence. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default LandingPage;
