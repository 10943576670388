import React, { useState, useEffect } from 'react';
import './App.css';
import { HashRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ControlPanel from './ControlPanel';
import Map from './Map';
import HydrantDashboard from './HydrantDashboard';
import { Auth } from 'aws-amplify';
import CustomSignIn from './CustomSignIn';
import LandingPage from './LandingPage';
import Navbar from './NavBar';
import Sidebar from './Sidebar';
import HydrantsDashboard from './HydrantsDashboard';

// Component to handle dynamic page titles
function AppContent({ user, userEmail, userToken, signOut, setUser }) {
    const location = useLocation();

    const getPageTitle = (path) => {
        if (path.startsWith('/hydrant/')) {
            return 'Dashboard';
        }
        switch (path) {
            case '/control-panel': return 'Control Panel';
            case '/map': return 'Map';
            case '/hydrants-dashboard': return 'Dashboard';
            default: return '';
        }
    };

    const pageTitle = getPageTitle(location.pathname);

    return (
        <>
            <Navbar isLoggedIn={!!user} onSignOut={signOut} pageTitle={pageTitle} />
            {user ? (
                <div className="app-container">
                    <Sidebar signOut={signOut} />
                    <main className="content">
                        <Routes>
                            <Route path="/control-panel" element={<ControlPanel userEmail={userEmail} userToken={userToken} />} />
                            <Route path="/map" element={<Map userEmail={userEmail} userToken={userToken} />} />
                            <Route path="/hydrant/:hydrantId" element={<HydrantDashboard userEmail={userEmail} userToken={userToken} />} />
                            <Route path="/hydrants-dashboard" element={<HydrantsDashboard userToken={userToken} />} />
                       </Routes>
                    </main>
                </div>
            ) : (
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/signin" element={<CustomSignIn setUser={setUser} />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
        </>
    );
}

function App() {
    const [user, setUser] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [loading, setLoading] = useState(true);  // Added loading state

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
                const email = currentUser.attributes.email;

                setUser(currentUser);
                setUserToken(jwtToken);
                setUserEmail(email);
            } catch {
                setUser(null);
                setUserToken(null);
                setUserEmail(null);
            }finally {
                setLoading(false);  // Set loading to false after check
            }
        };

        fetchUserDetails();
    }, []);

    const signOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
            window.location.reload();
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };
    // While loading, don’t render routes
    if (loading) {
        return <div>Loading...</div>;  // Simple loading message, you can customize this
    }
    return (
        <Router>
            <AppContent
                user={user}
                userEmail={userEmail}
                userToken={userToken}
                signOut={signOut}
                setUser={setUser}
            />
        </Router>
    );
}

export default App;
