import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';  // Added PuffLoader
import {
    Card, CardContent, Typography, IconButton, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Button, Tooltip,
    Grid, TextField, MenuItem, InputAdornment
} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import yellowIcon from './images/fire-hydrant-yellow.svg';
import orangeIcon from './images/fire-hydrant-orange.svg';
import redIcon from './images/fire-hydrant-red.svg';
import greenIcon from './images/fire_hydrante_green.svg';
import './ControlPanel.css';
import DownloadIcon from "./images/download.svg";

// Helper Functions (unchanged)
const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

const separateDateFromHours = (timestamp) => {
    const [date, time] = timestamp.split('T');
    return `${date.split('-').reverse().join('/')} ${time}`;
};

const getCurrentEstimation = (timestamp, debit_m3_s) => {
    const now = Date.now();
    const elapsedTimeInSeconds = (now - timestamp) / 1000;
    return (debit_m3_s * elapsedTimeInSeconds).toFixed(1);
};

const getDifferenceInMinutesSinceIsOpen = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp;
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return days > 0
        ? `${days}d ${hours % 24}h ago`
        : hours > 0
            ? `${hours}h ${minutes % 60}m ago`
            : `${minutes}m ago`;
};

const getColorByOpenTime = (timestamp) => {
    const now = Date.now();
    const minutes = (now - timestamp) / (1000 * 60);

    if (minutes <= 10) return yellowIcon;
    if (minutes <= 30) return orangeIcon;
    return redIcon;
};

const transformApiResponse = (apiResponse) => {
    return apiResponse.map(item => ({
        id: parseInt(item.hydrant_id),
        name: `Hydrant ${item.hydrant_id}`,
        status: item.current_state ? 'open' : 'closed',
        latitude: getLatitudeFromLocation(item.location),
        longitude: getLongitudeFromLocation(item.location),
        consumption: `${item.estimation.toFixed(2)} m³`,
        lastOpen: separateDateFromHours(item.date),
        openTime: getDifferenceInMinutesSinceIsOpen(item.open_time),
        currentEstimation: getCurrentEstimation(item.open_time, item.debit_m3_s),
        openColor: getColorByOpenTime(item.open_time),
        openTimeMinutes: (Date.now() - item.open_time) / (1000 * 60)
    }));
};

// Main Component
function ControlPanel({ userEmail, userToken }) {
    const [hydrants, setHydrants] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);  // Added loading state
    const previousHydrantsRef = useRef([]);

    const handleOpen = (message) => {
        setModalMessage(message);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        const fetchHydrants = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrants?client_email=${userEmail}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userToken}` }
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const data = await response.json();
                const newHydrants = transformApiResponse(data);

                newHydrants.forEach(newHydrant => {
                    const oldHydrant = previousHydrantsRef.current.find(h => h.id === newHydrant.id);
                    if (oldHydrant && oldHydrant.status !== newHydrant.status) {
                        handleOpen(`Hydrant ${newHydrant.id} is now ${newHydrant.status}`);
                    }
                });

                previousHydrantsRef.current = newHydrants;
                setHydrants(newHydrants);
            } catch (error) {
                console.error('There was a problem fetching hydrants:', error);
            } finally {
                setIsLoading(false);  // Set loading to false after fetching
            }
        };

        fetchHydrants();
        const interval = setInterval(fetchHydrants, 20000);

        return () => clearInterval(interval);
    }, [userToken, userEmail]);

    const filteredHydrants = hydrants.filter(hydrant => {
        const matchesSearch = hydrant.name.toLowerCase().includes(searchTerm.toLowerCase());

        if (filter === 'all') return matchesSearch;
        if (filter === 'open') return hydrant.status === 'open' && matchesSearch;
        if (filter === 'closed') return hydrant.status === 'closed' && matchesSearch;
        if (filter === 'recent') return hydrant.status === 'open' && hydrant.openTimeMinutes <= 10 && matchesSearch;
        if (filter === 'moderate') return hydrant.status === 'open' && hydrant.openTimeMinutes > 10 && hydrant.openTimeMinutes <= 30 && matchesSearch;
        if (filter === 'long') return hydrant.status === 'open' && hydrant.openTimeMinutes > 30 && matchesSearch;

        return matchesSearch;
    });

    // Display PuffLoader while loading
    if (isLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                <PuffLoader color="#21A0A4" size={100} />
            </Grid>
        );
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ color: '#b71c1c' }}>ALERT</DialogTitle>
                <DialogContent>
                    <DialogContentText>{modalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: '#b71c1c' }}>Close</Button>
                </DialogActions>
            </Dialog>

            <div className="dashboard-container">
                {/* Search Bar & Filter */}
                <div className="dashboard-filters">
                    <TextField
                        placeholder="Search Hydrants..."
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        sx={{marginRight: '20px'}}
                    />

                    {/* Icon Filters */}
                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <Tooltip title="Closed Hydrants">
                            <IconButton
                                onClick={() => setFilter(prevFilter => prevFilter === 'closed' ? 'all' : 'closed')}
                            >
                                <img
                                    src={greenIcon}
                                    alt="Closed"
                                    style={{width: '30px', height: '30px', opacity: filter === 'closed' ? 1 : 0.5}}
                                />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Opened ≤ 10 min">
                            <IconButton
                                onClick={() => setFilter(prevFilter => prevFilter === 'recent' ? 'all' : 'recent')}
                            >
                                <img
                                    src={yellowIcon}
                                    alt="Opened ≤ 10 min"
                                    style={{width: '30px', height: '30px', opacity: filter === 'recent' ? 1 : 0.5}}
                                />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Opened 10-30 min">
                            <IconButton
                                onClick={() => setFilter(prevFilter => prevFilter === 'moderate' ? 'all' : 'moderate')}
                            >
                                <img
                                    src={orangeIcon}
                                    alt="Opened 10-30 min"
                                    style={{width: '30px', height: '30px', opacity: filter === 'moderate' ? 1 : 0.5}}
                                />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Opened > 30 min">
                            <IconButton
                                onClick={() => setFilter(prevFilter => prevFilter === 'long' ? 'all' : 'long')}
                            >
                                <img
                                    src={redIcon}
                                    alt="Opened > 30 min"
                                    style={{width: '30px', height: '30px', opacity: filter === 'long' ? 1 : 0.5}}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>


                {/* Hydrant Cards */}
                <Grid container spacing={3}>
                    {filteredHydrants.map(hydrant => (
                        <Grid item xs={12} sm={6} md={4} key={hydrant.id}>
                            <Card className="hydrant-card" elevation={3}>
                                <CardContent>
                                    <div className="hydrant-header">
                                        <Typography variant="h2" className="hydrant-id">
                                            <Link to={`/hydrant/${hydrant.id}`}>Hydrant {hydrant.id}</Link>
                                        </Typography>
                                        <IconButton component={Link} to={`/map#${hydrant.id}`} aria-label="show on map"
                                                    className="location-icon">
                                            <PlaceIcon sx={{color: "#ea1111"}}/>
                                        </IconButton>
                                        {hydrant.status === 'open' ? (
                                            <Tooltip
                                                title={`Open ${hydrant.openColor === yellowIcon ? '< 10 min' : hydrant.openColor === orangeIcon ? '< 30 min' : '> 30 min'}`}>
                                                <img style={{width: '30px', height: '30px'}} className="blinking"
                                                     src={hydrant.openColor}
                                                     alt="Open"/>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Closed">
                                                <img style={{width: '30px', height: '30px'}} src={greenIcon}
                                                     alt="Closed"/>
                                            </Tooltip>
                                        )}
                                    </div>

                                    <Typography variant="body2" className="hydrant-info">
                                        <strong>{hydrant.status === 'open' ? 'Current Usage:' : 'Last Estimation:'}</strong>
                                        {hydrant.status === 'open' ? ` ${hydrant.currentEstimation} m³` : ` ${hydrant.consumption}`}
                                    </Typography>

                                    <Typography variant="body2" className="hydrant-info">
                                        <strong>{hydrant.status === 'open' ? 'Open for:' : 'Last Used:'}</strong>
                                        {hydrant.status === 'open' ? ` ${hydrant.openTime}` : ` ${hydrant.lastOpen}`}
                                    </Typography>

                                    <Typography variant="body2" className="google-maps-link">
                                        <a
                                            href={`https://www.google.com/maps?q=${hydrant.latitude},${hydrant.longitude}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View on Google Maps
                                        </a>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    );
}

export default ControlPanel;
