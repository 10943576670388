import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {GoogleMap, LoadScriptNext, MarkerF, MarkerClusterer} from '@react-google-maps/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material';
import yellowIcon from './images/fire-hydrant-yellow.svg';
import orangeIcon from './images/fire-hydrant-orange.svg';
import redIcon from './images/fire-hydrant-red.svg';
import greenIcon from './images/fire_hydrante_green.svg';
import cluster from './images/cluster_3.png';
import closeIcon from './images/close.svg';
import {Link} from 'react-router-dom';
import './Map.css';

const mapContainerStyle = {height: "100%", width: "100%"};
const defaultCenter = {lat: 40.213180, lng: -8.888282};

const handleCopyLink = (hydrant) => {
    const googleMapsLink = `https://www.google.com/maps?q=${hydrant.latitude},${hydrant.longitude}`;
    navigator.clipboard.writeText(googleMapsLink)
        .then(() => alert('Google Maps link copied to clipboard!'))
        .catch(err => console.error('Could not copy text: ', err));
};

const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

const separateDateFromHours = (timestamp) => {
    const [date] = timestamp.split('T');
    return date.split('-').reverse().join('/');
};

const getCurrentEstimation = (timestamp, debit_m3_s) => {
    const now = Date.now();
    const elapsedTimeInSeconds = (now - timestamp) / 1000;
    const estimatedVolume = debit_m3_s * elapsedTimeInSeconds;
    return parseFloat(estimatedVolume.toFixed(1));
};

const getDifferenceInMinutesSinceIsOpen = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp;
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let result = '';
    if (days > 0) result += `${days} day${days > 1 ? 's' : ''}, `;
    if (hours % 24 > 0) result += `${hours % 24} hour${hours % 24 > 1 ? 's' : ''}, `;
    if (minutes % 60 >= 0) result += `${minutes % 60} minute${minutes % 60 > 1 ? 's' : ''} ago`;

    return result.endsWith(', ') ? result.slice(0, -2) + ' ago' : result;
};

const getColorByOpenTime = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp;
    const minutes = difference / (1000 * 60);

    if (minutes <= 10) return 'yellow';
    if (minutes > 10 && minutes <= 30) return 'orange';
    return 'red';
};

const getIcon = (status, color) => {
    let iconUrl;
    if (status === 'Open') {
        iconUrl = color === 'yellow' ? yellowIcon : color === 'orange' ? orangeIcon : redIcon;
    } else {
        iconUrl = greenIcon;
    }

    return {
        url: iconUrl,
        scaledSize: new window.google.maps.Size(32, 32)
    };
};

const transformApiResponse = (apiResponse) => {
    return apiResponse.map(item => ({
        id: parseInt(item.hydrant_id),
        name: `Hydrant ${item.hydrant_id}`,
        status: item.current_state ? 'Open' : 'Close',
        latitude: getLatitudeFromLocation(item.location),
        longitude: getLongitudeFromLocation(item.location),
        consumption: `${item.estimation.toFixed(2)} m³`,
        lastOpen: separateDateFromHours(item.date),
        openTime: getDifferenceInMinutesSinceIsOpen(item.open_time),
        currentEstimation: `${getCurrentEstimation(item.open_time, item.debit_m3_s)} m³`,
        openColor: getColorByOpenTime(item.open_time),
    }));
};

function Map({userEmail, userToken}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [mapZoom, setMapZoom] = useState(12);
    const [selectedHydrant, setSelectedHydrant] = useState(null);
    const [hydrants, setHydrants] = useState([]);
    const [isBlinking, setIsBlinking] = useState(true);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const hydrantsRef = useRef(hydrants);
    const mapRef = useRef(null);  // Add this to get direct access to the map instance

    const handleOpen = (message) => {
        setModalMessage(message);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        const fetchHydrants = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrants?client_email=${userEmail}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${userToken}`}
                });

                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                const newHydrants = transformApiResponse(data);

                newHydrants.forEach(newHydrant => {
                    const oldHydrant = hydrantsRef.current.find(h => h.id === newHydrant.id);
                    if (oldHydrant && oldHydrant.status !== newHydrant.status) {
                        handleOpen(`Hydrant ${newHydrant.id} is now ${newHydrant.status}`);
                        setSelectedHydrant(newHydrant);
                    }
                });

                setHydrants(newHydrants);
                hydrantsRef.current = newHydrants;

                const hydrantId = parseInt(location.hash.replace("#", ""), 10);
                const hydrant = newHydrants.find(h => h.id === hydrantId);

                if (hydrant && mapRef.current) {
                    mapRef.current.panTo({lat: hydrant.latitude, lng: hydrant.longitude});
                    mapRef.current.setZoom(17);
                } else {
                    setMapCenter(defaultCenter);
                    setMapZoom(12);
                }
            } catch (error) {
                console.error('Problem fetching hydrants:', error);
            }
        };

        fetchHydrants();
        const interval = setInterval(fetchHydrants, 20000);
        return () => clearInterval(interval);
    }, [location, userEmail, userToken]);

    useEffect(() => {
        const interval = setInterval(() => setIsBlinking(prev => !prev), 750);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{color: '#b71c1c'}}>ALERT</DialogTitle>
                <DialogContent>
                    <DialogContentText>{modalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{color: '#b71c1c'}}>Close</Button>
                </DialogActions>
            </Dialog>

            <LoadScriptNext googleMapsApiKey="AIzaSyBypwJGJKzyume15VWHj4VdSI8qZwlLjVE">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={mapZoom}
                    center={mapCenter}
                    onLoad={(map) => mapRef.current = map}  // Capture the map instance
                >
                    <MarkerClusterer
                        options={{
                            styles: [
                                {
                                    textColor: 'white',
                                    url: cluster,
                                    height: 50,
                                    width: 50,
                                    textSize: 14,
                                    fontFamily: 'League Spartan',
                                    backgroundPosition: 'center',
                                    anchor: [0, 0],
                                    backgroundColor: '#21A0A4',
                                    borderRadius: '50%',
                                    lineHeight: '50px',
                                    textAlign: 'center'
                                },
                                {
                                    textColor: 'white',
                                    url: cluster,
                                    height: 60,
                                    width: 60,
                                    textSize: 16,
                                    fontFamily: 'League Spartan',
                                    backgroundPosition: 'center',
                                    anchor: [0, 0],
                                    backgroundColor: '#21A0A4',
                                    borderRadius: '50%',
                                    lineHeight: '60px',
                                    textAlign: 'center'
                                },
                                {
                                    textColor: 'white',
                                    url: cluster,
                                    height: 70,
                                    width: 70,
                                    textSize: 18,
                                    fontFamily: 'League Spartan',
                                    backgroundPosition: 'center',
                                    anchor: [0, 0],
                                    backgroundColor: '#21A0A4',
                                    borderRadius: '50%',
                                    lineHeight: '70px',
                                    textAlign: 'center'
                                }
                            ]
                        }}
                    >
                        {(clusterer) =>
                            hydrants.map(hydrant => (
                                <MarkerF
                                    key={hydrant.id}
                                    position={{ lat: hydrant.latitude, lng: hydrant.longitude }}
                                    clusterer={clusterer}
                                    icon={getIcon(hydrant.status, hydrant.openColor)}
                                    options={{
                                        opacity: hydrant.status === 'Open' ? (isBlinking ? 1 : 0.2) : 1
                                    }}
                                    onClick={() => {
                                        navigate(`/map#${hydrant.id}`);
                                        setSelectedHydrant(hydrant);
                                        if (mapRef.current) {
                                            mapRef.current.panTo({lat: hydrant.latitude, lng: hydrant.longitude});
                                            mapRef.current.setZoom(17);
                                        }
                                    }}
                                />
                            ))
                        }
                    </MarkerClusterer>

                    {selectedHydrant && (
                        <div className="custom-info-window">
                            <button className="close-info-window" onClick={() => setSelectedHydrant(null)}>
                                <img src={closeIcon} alt="Close" />
                            </button>
                            <Link variant='h4' to={`/hydrant/${selectedHydrant.id}`} className="hydrant-link">
                                {selectedHydrant.name}
                            </Link>
                            <p><b>Status:</b> {selectedHydrant.status}</p>
                            <p><b>{selectedHydrant.status === 'Open' ? 'Current Usage: ' : 'Last Usage: '}</b>
                                {selectedHydrant.status === 'Open' ? selectedHydrant.openTime : selectedHydrant.lastOpen}
                            </p>
                            <p>
                                <b>{selectedHydrant.status === 'Open' ? 'Current Water Estimation Consumption: ' : 'Last Estimated Water Consumption: '}</b>
                                {selectedHydrant.status === 'Open' ? selectedHydrant.currentEstimation : selectedHydrant.consumption}
                            </p>
                            <a
                                className="google-maps-link"
                                href={`https://www.google.com/maps?q=${selectedHydrant.latitude},${selectedHydrant.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View on Google Maps
                            </a>
                        </div>
                    )}
                </GoogleMap>
            </LoadScriptNext>
        </>
    );
}

export default Map;
