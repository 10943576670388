import React, { useState, useEffect, useRef  } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip, Divider } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, Tooltip as RechartsTooltip, CartesianGrid, ResponsiveContainer,ReferenceArea } from 'recharts';
import RefreshIcon from '../src/images/refresh.svg';
import html2canvas from 'html2canvas';
import DownloadIcon from '../src/images/download.svg';  // New Download Icon
import './HydrantDashboard.css';
import PlaceIcon from "@mui/icons-material/Place";

const separateDateFromHours = (timestamp) => {
    const [date, time] = timestamp.split('T');
    return `${date.split('-').reverse().join('/')} ${time}`;
};

const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

function HydrantDashboard({ userEmail, userToken }) {
    const { hydrantId } = useParams();
    const [hydrantData, setHydrantData] = useState(null);
    const [zoomDomain, setZoomDomain] = useState(null);
    const [selection, setSelection] = useState(null);
    const [dateFilter, setDateFilter] = useState('');
    const [estimationFilter, setEstimationFilter] = useState('');
    const [estimationOperator, setEstimationOperator] = useState('=');
    const chartRef = useRef(null);  // Reference for the chart div

    useEffect(() => {
        const fetchHydrantHistory = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrantSpecs?hydrant_id=${hydrantId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    },
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const data = await response.json();
                setHydrantData(data);
            } catch (error) {
                console.error('Error fetching hydrant history:', error);
            }
        };

        fetchHydrantHistory();
    }, [hydrantId, userToken]);

    if (!hydrantData) return null;

    const { location, cap_registed, caps_total, debit_m3_s } = hydrantData.specs;
    const totalEstimatedConsumption = parseFloat(hydrantData.historical_data.reduce(
        (acc, record) => acc + record.estimation, 0
    ).toFixed(1));

    // Prepare and sort data from oldest to most recent
    const chartData = hydrantData.historical_data
        .map(record => ({
            date: separateDateFromHours(record.date),
            estimation: parseFloat(record.estimation.toFixed(2)),
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

    // Handle Zoom: clicking zooms into the next 10 points
    const handleZoom = (e) => {
        if (e && e.activeLabel) {
            const selectedDate = e.activeLabel;
            const startIndex = chartData.findIndex(d => d.date === selectedDate);
            const endIndex = startIndex + 10;
            setZoomDomain(chartData.slice(startIndex, endIndex));
        }
    };

    // Reset Zoom
    const resetZoom = () => setZoomDomain(null);
    const handleMouseDown = (e) => {
        if (e && e.activeLabel) {
            setSelection({ start: e.activeLabel, end: null });
        }
    };

    const handleMouseMove = (e) => {
        if (selection && e && e.activeLabel) {
            setSelection(prev => ({ ...prev, end: e.activeLabel }));
        }
    };

    const handleMouseUp = () => {
        if (selection && selection.start && selection.end) {
            const startIndex = chartData.findIndex(d => d.date === selection.start);
            const endIndex = chartData.findIndex(d => d.date === selection.end);
            const zoomedData = chartData.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1);
            setZoomDomain(zoomedData);
        }
        setSelection(null);
    };

    const downloadChartAsPNG = async () => {
        if (!chartRef.current) {
            console.error('Chart reference is not defined.');
            return;
        }

        try {
            const canvas = await html2canvas(chartRef.current, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');

            const link = document.createElement('a');
            link.href = imgData;
            link.download = `Water_Consumption_Chart_${new Date().toISOString()}.png`;
            link.click();
        } catch (error) {
            console.error('Error downloading chart as PNG:', error);
        }
    };


    // Download Table as CSV
    const downloadTableAsCSV = () => {
        const headers = ['Usage Date', 'Estimated Consumption'];
        const rows = hydrantData.historical_data.map(record => [
            separateDateFromHours(record.date),
            `${parseFloat(record.estimation.toFixed(2))} m³`
        ]);

        const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `hydrant_${hydrantId}_data.csv`;
        link.click();
    };
    return (
        <div className="hydrant-history-container">
            <Grid container spacing={3} alignItems="stretch">
                {/* Hydrant Profile Section */}
                <Grid item xs={12} md={4}>
                    <Card className="hydrant-card" elevation={3} style={{ height: '37%' }}>
                        <CardContent>
                            <Typography variant="h6" className="table-title-chart">Hydrant {hydrantId}</Typography>

                            <div className="hydrant-detail" >
                                <Typography style={{
                                    fontFamily: 'League Spartan',
                                    fontSize: '1.0rem',
                                    fontWeight: 'bold',
                                }}
                                    variant="body1"><b>Location</b>
                                </Typography>
                                <text> {getLatitudeFromLocation(location)}, {getLongitudeFromLocation(location)}</text>
                            </div>

                            <div className="hydrant-detail">
                                <Typography style={{
                                    fontFamily: 'League Spartan',
                                    fontSize: '1.0rem',
                                    fontWeight: 'bold',
                                }} variant="body1" ><b>Debit</b> </Typography>
                                <text> {debit_m3_s} m³/s</text>
                            </div>

                            <div className="hydrant-detail">
                                <Typography style={{
                                    fontFamily: 'League Spartan',
                                    fontSize: '1.0rem',
                                    fontWeight: 'bold',
                                }} variant="body1"><b>Hydro-Defence Caps</b></Typography>
                                <text> {cap_registed}/{caps_total}</text>
                            </div>

                            <div className="hydrant-detail">
                                <Typography style={{
                                    fontFamily: 'League Spartan',
                                    fontSize: '1.0rem',
                                    fontWeight: 'bold',
                                }} variant="body1"><b>Total Water Consumption</b> </Typography>
                                <text> {totalEstimatedConsumption} m³</text>
                            </div>
                        </CardContent>
                    </Card>

                    <Card ref={chartRef} className="hydrant-chart-card" elevation={3} style={{ height: '51.5%', marginTop: '20px',fontFamily: 'League Spartan' }} >
                        <CardContent>
                            <Typography variant="h6" className="table-title-chart">Water Consumption
                                <IconButton
                                    onClick={downloadChartAsPNG}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        transition: 'transform 0.2s',
                                        zIndex: 2
                                    }}
                                >
                                    <img src={DownloadIcon} alt="Download Chart" style={{ width: '15px', height: '15px' }} />
                                </IconButton>
                                {zoomDomain && (
                                    <IconButton onClick={resetZoom} >
                                        <img src={RefreshIcon} alt="Reset Zoom" style={{ width: '10px', height: '10px' }} />
                                    </IconButton>
                                )}</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <AreaChart
                                    data={zoomDomain || chartData}
                                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                >
                                    <defs>
                                        <linearGradient id="colorEstimation" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#21A0A4" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#21A0A4" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <XAxis style={{ fontSize: '0.8rem' }} dataKey="date" tickFormatter={(date) => date.split(' ')[0]} reversed={true}/>
                                    <YAxis style={{ fontSize: '0.8rem' }} tickFormatter={(value) =>
                                        value >= 1e6 ? `${value / 1e6}M m³` :
                                            value >= 1e3 ? `${value / 1e3}K m³` :
                                                `${value} m³`
                                    }/>
                                    <RechartsTooltip formatter={(value) => `${value} m³`} />
                                    <Area type="monotone" dataKey="estimation" stroke="#21A0A4" fillOpacity={1} fill="url(#colorEstimation)" />
                                    {selection && selection.start && selection.end && (
                                        <ReferenceArea x1={selection.start} x2={selection.end} strokeOpacity={0.3} />
                                    )}
                                </AreaChart>
                            </ResponsiveContainer>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Hydrant Usage History */}
                <Grid item xs={12} md={8}>
                    <Paper className="hydrant-history-table" elevation={3} style={{ height: '98%', maxHeight: '600px', overflowY: 'auto', fontFamily: 'League Spartan' }}>
                        <Typography variant="h6" className="table-title">Usage History</Typography>
                        <IconButton onClick={downloadTableAsCSV} style={{ position: 'absolute', top: 10, right: 10 }}>
                            <img src={DownloadIcon} alt="Download CSV" style={{ width: '15px', height: '15px' }} />
                        </IconButton>
                        {/* Filter Inputs Aligned with Headers */}
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow  style={{
                                    fontFamily: 'League Spartan',
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                }}>
                                    <TableCell align="center"> <Typography>Usage Date</Typography></TableCell>
                                   <TableCell align="center"> <Typography>Estimated Consumption</Typography></TableCell>

                                </TableRow>
                                <TableRow>

                                    {/* Filter for Usage Date */}
                                    <TableCell align="center" className="table-header">
                                        <input
                                            type="date"
                                            value={dateFilter}
                                            onChange={(e) => setDateFilter(e.target.value)}
                                            style={{
                                                width: '100%',
                                                fontFamily: 'League Spartan',
                                                padding: '8px',
                                                height: '40px',
                                                boxSizing: 'border-box'
                                            }}
                                            placeholder="Filter by Date"
                                        />

                                    </TableCell>
                                   {/* Filter for Estimated Consumption */}
                                    <TableCell align="center" className="table-header">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <select
                                                value={estimationOperator}
                                                onChange={(e) => setEstimationOperator(e.target.value)}
                                                style={{
                                                    width: '20%',
                                                    fontFamily: 'League Spartan',
                                                    padding: '8px',
                                                    height: '40px',
                                                    marginRight: '5px',
                                                    boxSizing: 'border-box'
                                                }}
                                            >
                                                <option value="=">=</option>
                                                <option value="<">&lt;</option>
                                                <option value=">">&gt;</option>
                                            </select>

                                            <input
                                                type="number"
                                                value={estimationFilter}
                                                onChange={(e) => setEstimationFilter(e.target.value)}
                                                placeholder="Estimation (m³)"
                                                style={{
                                                    width: '75%',
                                                    fontFamily: 'League Spartan',
                                                    padding: '8px',
                                                    height: '40px',
                                                    boxSizing: 'border-box'
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {/* Table Body */}
                            <TableBody>
                                {hydrantData.historical_data
                                    .filter(record => {
                                        const recordDate = separateDateFromHours(record.date).split(' ')[0].split('/').reverse().join('-');
                                        const meetsDate = !dateFilter || recordDate === dateFilter;

                                        const estimation = parseFloat(record.estimation.toFixed(2));
                                        const meetsEstimation = !estimationFilter || (
                                            estimationOperator === '=' && estimation === parseFloat(estimationFilter) ||
                                            estimationOperator === '<' && estimation < parseFloat(estimationFilter) ||
                                            estimationOperator === '>' && estimation > parseFloat(estimationFilter)
                                        );

                                        return meetsDate && meetsEstimation;
                                    })
                                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                                    .map((record, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell align="center">{separateDateFromHours(record.date)}</TableCell>
                                            <TableCell align="center">{parseFloat(record.estimation.toFixed(2))} m³</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>

            </Grid>
        </div>
    );
}

export default HydrantDashboard;
